html,
body {
  margin: 0;
  padding: 0;
  background-color: #F5F7FA;
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1, 'ss01' 1, 'cv05' 1, 'cv06' 1, 'cv11' 1; /* 'liga' 1, 'calt' 1 are fixes for Chrome. ss01: open digits, cv05: l with tail, cv06: simplified u, cv11: single-storey a. For more see https://rsms.me/inter/lab */
  color: #232628;
  min-width: 1024px;
}

a {
  color: inherit;
}

* {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1, 'ss01' 1, 'cv05' 1, 'cv06' 1, 'cv11' 1; /* 'liga' 1, 'calt' 1 are fixes for Chrome. ss01: open digits, cv05: l with tail, cv06: simplified u, cv11: single-storey a. For more see https://rsms.me/inter/lab */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
  * {
    font-family: InterVariable, sans-serif;
    font-feature-settings: 'liga' 1, 'calt' 1, 'ss01' 1, 'cv05' 1, 'cv06' 1, 'cv11' 1; /* 'liga' 1, 'calt' 1 are fixes for Chrome. ss01: open digits, cv05: l with tail, cv06: simplified u, cv11: single-storey a. For more see https://rsms.me/inter/lab */
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

input,
textarea {
  resize: none;
}

/*

Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #383a42;
  font-family: monospace;
}

.hljs code,
.hljs span {
  font-family: monospace;
}

.hljs-comment,
.hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #a626a4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e45649;
}

.hljs-literal {
  color: #0184bb;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50a14f;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #c18401;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078f2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

.hljs-module-identifier {
  color: #4078f2;
}
